<template>
  <!-- head -->
  <div v-if="theuser.raw_user_meta_data != undefined">
    <div style="position: relative">
      <q-img height="250px" :src="profile.cover">
        <!-- <q-btn
          class="q-ma-md transparent flat absolute-top-right"
          icon="edit"
        /> -->
      </q-img>
      <q-avatar class="myavatar" size="200px">
        <img :src="profile.avatar" />
      </q-avatar>
    </div>
    <h5 class="myname text-center">
      {{ profile.nickname }} - {{ $t(levels[profile.ulevel]) }}
    </h5>
    <p class="q-ma-md text-center">
      {{ $t("wallet") }}:
      <a :href="chainscan + '/address/' + theuser.raw_user_meta_data.wallet">
        {{ theuser.raw_user_meta_data.wallet }}
      </a>
    </p>
    <p class="q-ma-md text-center">
      {{ profile.bio }}
    </p>
    <div class="text-center">
      <a v-if="profile.website" :href="profile.website">
        <q-avatar class="q-ma-sm">
          <img src="@/assets/website.png" size="lg" />
        </q-avatar>
      </a>

      <a
        v-if="profile.facebook"
        :href="'https://www.facebook.com/' + profile.facebook"
      >
        <q-avatar class="q-ma-sm">
          <img src="@/assets/facebook.png" size="lg" />
        </q-avatar>
      </a>
      <a
        v-if="profile.twitter"
        :href="'https://www.twitter.com/' + profile.twitter"
      >
        <q-avatar class="q-ma-sm">
          <img src="@/assets/twitter.png" size="lg" />
        </q-avatar>
      </a>
      <a
        v-if="profile.instagram"
        :href="'https://www.instagram.com/' + profile.instagram"
      >
        <q-avatar class="q-ma-sm">
          <img src="@/assets/instagram.png" size="lg" />
        </q-avatar>
      </a>
      <a v-if="profile.shop_link" :href="profile.shop_link">
        <q-avatar class="q-ma-sm myicon">
          <q-icon name="shopping_cart" class="text-orange" size="lg" />
        </q-avatar>
      </a>
      <a v-if="profile.video_link" :href="profile.video_link">
        <q-avatar class="q-ma-sm myicon">
          <q-icon name="movie" class="text-primary" size="lg" />
        </q-avatar>
      </a>
      <a v-if="profile.blog_link" :href="profile.blog_link">
        <q-avatar class="q-ma-sm myicon">
          <q-icon name="library_books" class="text-teal" size="lg" />
        </q-avatar>
      </a>
    </div>
    <div v-if="!user" class="q-pa-md q-mt-lg q-mb-lg text-center">
      <q-btn size="lg" @click="join" class="q-pl-xl q-pr-xl" color="blue">
        {{ $t("join_nft") }}
      </q-btn>
    </div>
    <div class="q-pa-md q-mt-lg q-mb-lg text-center">
      <p v-if="user" class="q-ma-md text-center">
        <q-btn
          size="lg"
          class="q-ma-md q-pl-xl q-pr-xl"
          color="red"
          @click="this.$router.push('/team/' + profile.id)"
        >
          {{ $t("Teams") }}
        </q-btn>
        <q-btn
          class="q-ma-xs"
          color="orange"
          :label="$t('share')"
          size="lg"
          @click="share_to(profile.nickname, profile.bio, share_url)"
        />
        <input hidden type="text" :value="share_url" id="my_share_url" />
      </p>
      <p v-else>
        <q-btn
          size="lg"
          class="q-ma-md q-pl-xl q-pr-xl"
          color="red"
          @click="this.$router.push('/team/' + profile.id)"
        >
          {{ $t("Teams") }}
        </q-btn>
        <q-btn
          class="q-ma-xs"
          color="orange"
          :label="$t('share')"
          size="lg"
          to="/login"
        />
      </p>

      <q-btn-dropdown
        size="lg"
        class="q-ma-md q-pl-xl q-pr-xl"
        v-if="user && profile.role == 1"
        color="primary"
        label="SET MEMBERSHIP"
      >
        <q-list>
          <q-item clickable v-close-popup @click="set_level(0)">
            <q-item-section>
              <q-item-label>Free User</q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup @click="set_level(1)">
            <q-item-section>
              <q-item-label>Bronze</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-close-popup @click="set_level(2)">
            <q-item-section>
              <q-item-label>Silver</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-close-popup @click="set_level(3)">
            <q-item-section>
              <q-item-label>Gold</q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup @click="set_level(4)">
            <q-item-section>
              <q-item-label>Ruby</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </div>
  </div>

  <!-- NFT List -->
  <div class="q-pa-md row my-list">
    <div
      v-for="k in mytokens"
      :key="k.id"
      class="q-pa-md col-xs-12 col-sm-6 col-md-4 col-lg-3"
    >
      <q-card class="my-card" @click="show_nft(k.contract)">
        <q-img class="q-pa-md" :ratio="1" :src="k.data.image" />
        <q-card-section>
          <div class="text-h6">{{ k.data.name }}</div>
          <div class="text-body2">
            {{ pdate(k.created_at) }}
          </div>
          <div class="text-body2">{{ $t("price") }}: ${{ k.data.price }}</div>
          <div class="text-body2">
            CONTRACT:
            <a :href="chainscan + 'address/' + k.contract">
              {{
                k.contract.substring(0, 15) + "..." + k.contract.substring(35)
              }}
            </a>
          </div>

          <div v-if="k.tid" class="text-body2">
            TID:
            <a
              :href="
                'https://opensea.io/assets/matic/' + k.contract + '/' + k.tid
              "
              >{{ k.tid.substring(0, 15) + "..." + k.tid.substring(70) }}</a
            >
          </div>
          <!-- <div class="text-body2">
            Total: {{ k.data.count }} Remains: {{ k.balance }}
          </div> -->
        </q-card-section>

        <!-- <q-card-section class="q-pt-none">
          <q-btn
            class="my-card-btn"
            color="blue"
            :label="$t('view_origin')"
            @click="show_nft(k.contract)"
          />
        </q-card-section> -->
      </q-card>
    </div>
  </div>
</template>
<style lang="sass" scoped>
.my-link
  display: inline-block
  width: 100%
  overflow: hidden
  text-overflow: ellipsis

.my-list
  margin-left: 0rem
  margin-right: 0rem
.myicon
  background-color: lightgrey
.myavatar
  position: absolute
  left: 50%
  margin-left: -100px
  bottom: -100px
  img
    border: 10px solid white

.myname
  margin-top: 120px
  margin-bottom: 0px

.my-card
  cursor: pointer
  .my-card-btn
    width: 100%
</style>

<script>
import router from "@/router";
import { useRoute } from "vue-router";
import { ref } from "vue";
import { onBeforeMount } from "vue";
import { supabase as s } from "../supabase";
import { chainscan, get_member_level, levels, share_to } from "../web3";

export default {
  name: "Profile",
  setup() {
    const mytokens = ref([]);
    const profile = ref({});
    const theuser = ref({});
    const user = ref(s.auth.user());
    const share_url = ref("");
    const ulevel = ref(0);
    console.log(user);
    onBeforeMount(async () => {
      const uid = useRoute().params.uid;
      console.log(uid);
      let { data: u, error } = await s.from("users").select("*").eq("id", uid);
      if (error) console.log(error);
      console.log(u);
      theuser.value = u[0];
      console.log(theuser.value);

      // ulevel.value = await get_member_level(
      //   theuser.value.raw_user_meta_data.wallet
      // );

      {
        var queries = {};
        window.location.search
          .substr(1)
          .split("&")
          .forEach(function (item) {
            queries[item.split("=")[0]] = item.split("=")[1];
          });

        // set refer to local storage, send to server when user signin
        // if local has already has a referer or currently exist login user don't need set referer
        // only set referer for the clean new user first visit
        if (!localStorage.getItem("referer") && !user.value) {
          // query refer user info
          if (queries["rid"] == "undefined") {
            console.log("No referer rid, use current profile user as rid");
            if (!localStorage.getItem("referer") && !user.value) {
              localStorage.setItem("referer", theuser.value.id);
              console.log("set local referer id:", theuser.value.id);
            }
          } else {
            const rid = queries["rid"];
            console.log("referer uid", rid);
            let { data: u, error } = await s
              .from("users")
              .select("*")
              .eq("id", rid);
            if (error) console.log(error);
            else {
              console.log("Query refer user:", u[0]);
              localStorage.setItem("referer", u[0].id);
              console.log("set local referer id:", u[0].id);
            }
          }
        }
      }

      {
        console.log("start get tokens of the user");
        let { data: tokens, error } = await s
          .from("tokens")
          .select("*")
          .eq("owner", theuser.value.raw_user_meta_data.wallet)
          .eq("show", true)
          .order("created_at", { ascending: false });
        console.log(tokens);
        mytokens.value = tokens;
      }

      {
        let { data: p, error } = await s
          .from("profiles")
          .select("*")
          .eq("id", theuser.value.id);
        if (error) console.log(error);
        console.log(p[0]);
        profile.value = p[0];

        share_url.value =
          "https://cnft.us/profile/" + p[0].id + "?rid=" + user.value.id;
      }
    });

    return {
      chainscan,
      user,
      theuser,
      mytokens,
      profile,
      share_url,
      share_to,
      levels,
      ulevel,
    };
  },

  methods: {
    join() {
      console.log("call join");
      console.log("src:", window.location.pathname);
      this.$router.push({
        path: "/login",
        query: {
          src: window.location.pathname,
        },
      });
    },
    show_nft(id) {
      router.push("/nft/" + id);
    },
    pdate(dstr) {
      const date = new Date(dstr);
      return new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(
        date
      );
    },
    async set_level(level) {
      console.log("set user level:", level);
      const { data, error } = await s
        .from("profiles")
        .update({ ulevel: level })
        .match({ id: this.theuser.id });
      if (error) {
        console.log(error);
      } else {
        console.log(data);
        this.profile = data[0];
      }
    },
  },
};
</script>